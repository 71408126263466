/* eslint-disable */
import './polyfills';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import { Provider } from 'react-redux';
import 'balloon-css/balloon.min.css';
import { syncHistoryWithStore } from 'react-router-redux';
import { ThemeProvider } from 'styled-components';
import './theme/globalStyles';
import configureStore from './configureStore';
import Routes from './routes';
import { getProp } from './utilities/';
import ErrorBoundary from './containers/Errors/ErrorBoundary';
import { loadTranslations, resolveLangFromCookies } from './configureTrans';
import { prop } from 'eshop-defaults';
import APIC, { default as API } from './services/API';
import { theme } from './theme/theme';
import { isSSR } from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import ReactGA from 'react-ga';
import { loadableReady } from '@loadable/component';

declare global {
  interface Window {
    __INIT_DATA__: any;
    __REDUX_STATE__: any;
    Packeta: any;
    trackHeurekaProductDetail: any;
    initializeWidget: any;
  }
}

API.setBaseUrl(process.env.REACT_APP_API_BASE_URL || '');

const reduxState = getProp(window, '__REDUX_STATE__');
if (!reduxState || reduxState === '{{SSR_INITIAL_STATE}}') {
  window.__REDUX_STATE__ = {};
}
const store = configureStore(window.__REDUX_STATE__);
const historyObj = syncHistoryWithStore(browserHistory, store);

// if (!isSSR()) {
//   ReactGA.initialize('G-NDQQP8GKZ3');
//   historyObj.listen(location => {
//     ReactGA.set({ page: location.pathname }); // Update the user's current page
//     ReactGA.pageview(location.pathname); // Record a pageview for the given page
//   });
// }

// const loadTranslationsData = () => loadTranslations(location);
// const lang = resolveLangFromCookies('');

if (typeof window !== 'undefined') {
  if (window.__REDUX_STATE__) {
    API.setToken(prop(window.__REDUX_STATE__, 'auth.token', undefined));
  }
  ReactDOM.hydrate(
    <ThemeProvider theme={theme}>
      {/* <LanguageProvider
      translations={window.__INIT_DATA__.translations}
       loadCatalogues={loadTranslationsData}
        loadingContainer={<FullscreenLoading />}
      > */}
      <ErrorBoundary>
        <Provider store={store}>
          <Routes history={historyObj} lang={'sk'} />
        </Provider>
      </ErrorBoundary>
      {/* </LanguageProvider> */}
    </ThemeProvider>,
    document.getElementById('root'),
  );
}

export { store };
