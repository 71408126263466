import * as React from 'react';
import { __ } from 'react-i18n';
import { connectSsr } from 'ssr-service';
import { connect } from 'react-redux';
import { SanitasCartDeliveryPart as CartDeliveryPart } from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import {
  fetchDeliveryPaymentInfo,
  cartDelPayInfoIsFetchingSelector,
  updateCart,
  cartDelPayInfoByIdSelector,
  cartDelPayInfoIdsSelector,
} from './cartSlice';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';

interface Props {
  user: any;
  dispatch: any;
  data: ThenArg<typeof API.getCart> | null;
  isFetching: boolean;
  delAndPayInfoIds: string[];
  delAndPayInfoById: Record<string, ThenArg<typeof API.getDeliveryPaymentInfo>>;
  isFetchingDelAndPayInfo: boolean;
  showProblems: boolean;
}

class CartThirdStep extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch } = props;
    try {
      await dispatch(fetchDeliveryPaymentInfo());

      return;
    } catch (exp) {
      console.log(exp);
      return;
    }
  }

  componentDidMount() {
    window.addEventListener('message', this.handleMessageFromIframe);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessageFromIframe);
  }

  public onChooseZasielkovna = a => {
    if (a) {
      this.props.dispatch(
        updateCart({
          ...this.props.data,
          delivery: { id: 'ZASIELKOVNA' },
          zasielkovna_id: `${a.id}`,
          zasielkovna_payload: a,
        }),
      );
    }
  };

  public openZasielkovna = () => {
    window.Packeta.Widget.pick(
      process.env.REACT_ZASIELKOVNA_API_KEY,
      this.onChooseZasielkovna,
      { country: 'sk', language: 'sk' },
    );
  };

  public setIframeBackgroundColor = (color: string) => {
    const wrapper = document.getElementById('balikovo.wrapper');
    if (wrapper) {
      const iframe = wrapper.querySelector('iframe') as HTMLIFrameElement;
      if (iframe) {
        iframe.style.backgroundColor = color;
      }
    }
  };

  public openSPSWidgetTest = () => {
    if (window.initializeWidget) {
      window.initializeWidget();
      setTimeout(() => {
        this.setIframeBackgroundColor('rgba(0, 0, 0, 0.3)');
      }, 1000);
    }
  };

  onChooseSPS = (data: any) => {
    if (data) {
      const cart = this.props.data;
      this.props.dispatch(
        updateCart({
          ...cart,
          delivery: { id: 'BALIKOMAT' },
          pickup_id: data.id,
          pickup_payload: data,
        }),
      );
    }
  };

  handleMessageFromIframe = (event: MessageEvent) => {
    if (
      event.origin === 'https://balikomat.sps-sro.sk' &&
      event.data.command === 'close'
    ) {
      this.onChooseSPS(event.data.data);
    }
  };

  public render() {
    const {
      data,
      isFetching,
      delAndPayInfoIds,
      delAndPayInfoById,
      isFetchingDelAndPayInfo,
      user,
      showProblems,
    } = this.props;

    return (
      <>
        <MetaTags tags={{ title: __('Košík - Doprava a platba') }} />
        <CartDeliveryPart
          data={data}
          isFetching={isFetching}
          delAndPayInfoIds={delAndPayInfoIds}
          delAndPayInfoById={delAndPayInfoById}
          isFetchingDelAndPayInfo={isFetchingDelAndPayInfo}
          updateCart={this.updateCart}
          user={user}
          showProblems={showProblems}
          openZasielkovnaWidget={this.openZasielkovna}
          openSPSWidget={this.openSPSWidgetTest}
        />
      </>
    );
  }

  private updateCart = data => {
    this.props.dispatch(updateCart(data, false));
  };
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    delAndPayInfoIds: cartDelPayInfoIdsSelector(state),
    delAndPayInfoById: cartDelPayInfoByIdSelector(state),
    isFetchingDelAndPayInfo: cartDelPayInfoIsFetchingSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'CartThirdStep' })(CartThirdStep),
);
